@import "src/scss/abstracts/variables";


.circle {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 10px;
  background: $gray-bg;
  border: 1px solid $gray-secondary;
  color: $gray-secondary;
  text-align: center;
  font-weight: 600;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 768px) {
    width: 48px;
    height: 48px;
  }
}

.active {

  color: $text-primary;
  font-weight: bold;

  .circle {
    border-width: 2px;
    border-color: $text-primary;
    color: $text-primary;

    &.completed {
      border-color: $purple;
    }
  }
}

.completed {
  background: $purple;
  color: white;

}


.dash {
  position: absolute;
  top: 33%;
  height: 2px;
  z-index: -2;
  width: 85%;
  left: 7.5%;
  background-image: linear-gradient(to right, #333 40%, rgba(255, 255, 255, 0) 20%);
  background-position: top;
  background-size: 3px 1px;
  background-repeat: repeat-x;
}

.left-side-item {
  min-height: 70vh;
  width: 80px;
  background-image: url("./left.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.right-side-item {
  min-height: 70vh;
  width: 80px;
  background-image: url("./right.png");
  background-repeat: no-repeat;
  background-size: contain;
}