@font-face {
  font-family: 'Museo Sans';
  src: url('fonts/MuseoSans-100.woff2') format('woff2'),
  url('fonts/MuseoSans-100.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('fonts/MuseoSans-300Italic.woff2') format('woff2'),
  url('fonts/MuseoSans-300Italic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('fonts/MuseoSans-700.woff2') format('woff2'),
  url('fonts/MuseoSans-700.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('fonts/MuseoSans-300.woff2') format('woff2'),
  url('fonts/MuseoSans-300.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

