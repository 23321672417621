@import "src/scss/abstracts/variables";

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  font-size: 14px;
  cursor: pointer;

  @media screen and (min-width: 768px) {
    font-size: 18px;
  }
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: $gray-bg;
  border: 1px solid $text-primary;
  border-radius: 50%;
}

.container:hover input ~ .checkmark {
  background-color: $orange;
  border: none;
}

.container input:checked ~ .checkmark {
  background-color: $orange;
  border: none;
}


.checkmark:after {
  content: "";
  width: 10px;
  height: 10px;
  left: 7.5px;
  top: 7.5px;
  background-color: $gray-bg;
  border-radius: 30px;
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.date {
  background-color: $gray-bg;
  min-width: 310px;
  border: 1px solid $black;
  padding: 2px;
}