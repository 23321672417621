.button {
  width: 280px;
  background-color: #20212A;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  @media only screen and (min-width: 768px) {
    width: 26.25rem;
    height: 3rem;
  }

  &:hover:not(.rainbow, .ghost , .ghost-sm) {
    border: 0.125rem solid #20212A
  }
}

.small {
  max-width: 14.5rem;
}

.rainbow {
  background: linear-gradient(to right, #FD880F, #E93322, #9E1FCD);

  &:hover {
    background: linear-gradient(to left, #FD880F, #E93322, #9E1FCD);
  }
}

.ghost {
  background-color: transparent;
  box-shadow: none;
  color: #000;
  font-weight: normal;
  border: 0.125rem solid #20212A;

  &:hover {
    font-weight: bold;
    border-width: 0.1875rem;
  }

  &-sm {
    background-color: transparent;
    box-shadow: none;
    color: #000;
    border: 2px solid #000000;
    width: 9.9375rem;
    height: 2.25rem;
    font-weight: normal;

    &:hover {
      font-weight: bold;
      border-width: 0.1875rem;
    }
  }
}

button[disabled] {
  pointer-events: none;
  background: #BDBDBD;

  &.ghost, &.ghost-sm {
    background-color: transparent;
    color: #BDBDBD;
    border-color: #BDBDBD;
  }
}