.page {
  min-height: 100vh;
  background: url("./bg-m.png") center -20px no-repeat;
  background-size: cover;


  @media only screen and (min-width: 768px) {
    min-height: 80vh;
    background-image: url("./bg.png");
    background-size: contain;
  }
}