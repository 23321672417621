* {
  box-sizing: border-box;
  font-family: 'Museo Sans', sans-serif;
  -font-smoothing: antialiased;
  -webkit-font-smoothing: subpixel-antialiased;
  margin: 0;
}

html {
  font-size: 16px;
  background-color: $gray-bg;
}

body {
  margin: 0;
  font-size: 0.875rem;
  position: relative;
}


strong {
  color: inherit;
  line-height: inherit;
  -webkit-transform: translate3d(0, 0, 0) !important;
  transform: translate3d(0, 0, 0) !important;
  text-rendering: geometricPrecision;
}

