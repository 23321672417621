$blood-orange: #ea3523;
$orange: #fd880f;
$green: #56b95f;
$purple: #9e1fcd;

$black: #20212a;
$gray-secondary: #828282;
$gray-image-bg: #e5e5e5;
$gray-bg: #f4f4f4;
$white: #ffffff;

$text-primary: #20212a;
$text-secondary: #9e1fcd;

