@import "src/scss/abstracts/variables";

@media only screen and (min-width: 1024px) {
  .swiper-product > .swiper-button-prev, .swiper-product > .swiper-button-next {
    display: none !important;
  }
}

.mySwiper {
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-self: center;
  align-items: center;
  width: 100%;
  padding: 2rem;
}

.mySwiper .swiper-slide {
  padding: 0.1875rem;
  width: fit-content !important;
  max-height: 80%;
  display: flex;
  opacity: 0.6;
}

.mySwiper .swiper-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
  box-shadow: 0 0 10px $black;
}

.swiper-slide img {
  margin: auto 0;
  display: block;
  width: 100%;
  height: 80%;
  object-fit: contain;
}

.swiper-button-prev:after {
  width: 30px;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M10 19l-7-7m0 0l7-7m-7 7h18'/%3E%3C/svg%3E") !important;
}

.swiper-button-next:after {
  width: 30px;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M14 5l7 7m0 0l-7 7m7-7H3' /%3E%3C/svg%3E") !important;
}