.custom-input {
  background-color: transparent;
  width: 97%;
}

.register-page {
  min-height: 100vh;
  background-size: cover;


  @media only screen and (min-width: 768px) {
    min-height: 80vh;
    background-size: contain;
  }
}