h1 {
  font-size: 26px;
  line-height: 31px;
  font-weight: bold;
  text-transform: uppercase;
}

h2 {
  font-size: 26px;
  line-height: 32px;
  text-transform: uppercase;
}

@media only screen and (min-width: 768px) {
  h1 {
    font-size: 36px;
    line-height: 43px;
    font-weight: bold;
  }
  h2 {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
  }
}