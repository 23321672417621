@import "src/scss/abstracts/variables";

.filter {
  display: none;

  &__container {
    margin: 0.75rem 0.625rem 0 0;
  }

  & + label {
    display: block;
    width: fit-content;
    border: 0.125rem solid $black;
    text-transform: uppercase;
    font-weight: bold;
    padding: 8px;
    font-size: 0.875rem;
  }

  &:checked + label {
    border-color: $orange;
    background-color: $orange;
    color: $white;
  }
}