@import '../../scss/abstracts/variables';

.custom-input {
  background-color: transparent;
  width: 97%;
}

.login-page {
  height: 70vh;
}

$checkbox-background: #fff;
$checkbox-border: $orange;
$checkbox-checked: $checkbox-border;


@mixin vh() {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.remember {
  @include vh();

  &:focus {
    + label {
      &:before {
      }
    }
  }

  + label {
    position: relative;
    padding: 0.25rem 0 0 3rem;

    user-select: none;
    color: $checkbox-background;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 1.75rem;
      height: 1.75rem;
      border: 2px solid $checkbox-border;
      border-radius: 0.25rem;
      background: $checkbox-background;
      text-align: center;
      transition: background 200ms ease-out;
    }

    &:after {
      content: "";
      position: absolute;
      transform: scale(0);
      transition: transform 200ms ease-out;
    }
  }
}


.remember {
  + label {
    &:after {
      background-color: transparent;
      content: "";
      display: block;
      position: absolute;
      left: 0.65rem;
      top: 0.25rem;
      width: 0.5rem;
      height: 1rem;
      opacity: 0;
      border-bottom: 3px solid $checkbox-checked;
      border-right: 3px solid $checkbox-checked;
      transform: rotate(45deg);
      transition: border-color 0.3s ease;
    }
  }

  &:checked {
    + label {
      &:before {
        content: "";
      }

      &:after {
        content: "";
        opacity: 1;
      }
    }
  }
}

.form-checkbox {
  position: relative;
  display: block;
}

.wrapper {
  text-align: center;
  top: -10px;
  left: 0;
  right: 0;
  display: none;
  justify-content: center;
  position: absolute;
  z-index: -1;

  @media only screen and (min-width: 768px) {
    display: flex;
  }


  svg {
    width: 300%;
  }

  svg path {
    d: path('M908.82 408.989C882.826 583.057 803.305 565.209 665.5 627C564.331 672.364 435 807.5 288.5 726.5C142 645.5 -74.2451 351.28 26.3014 177.128C126.848 2.97684 180.607 -50.5181 360.484 53.3335C540.36 157.185 975.214 44.1012 908.82 408.989Z');
    animation: morph 12.5s infinite;
  }

  svg:hover path {
    d: path('M908.82 408.989C882.826 583.057 803.305 565.209 665.5 627C564.331 672.364 435 807.5 288.5 726.5C142 645.5 -74.2451 351.28 26.3014 177.128C126.848 2.97684 180.607 -50.5181 360.484 53.3335C540.36 157.185 975.214 44.1012 908.82 408.989Z');
  }

  @keyframes morph {
    0%, 100% {
      d: path('M908.82 408.989C882.826 583.057 803.305 565.209 665.5 627C564.331 672.364 435 807.5 288.5 726.5C142 645.5 -74.2451 351.28 26.3014 177.128C126.848 2.97684 180.607 -50.5181 360.484 53.3335C540.36 157.185 975.214 44.1012 908.82 408.989Z');
    }
    50% {
      d: path('M842,346.9c52,78,12.8,293.2-125,355c-101.2,45.4-360.7,36.3-439-69c-110-148-315.8-246.5-215-420  C163.5,38.7,249.8,42.3,467,26.9C805,2.9,748.9,207.2,842,346.9z')
    }
  }
}

.mobile {
  text-align: center;
  top: -10px;
  left: 0;
  right: 0;
  justify-content: center;
  position: absolute;
  z-index: -1;
  display: flex;
  @media only screen and (min-width: 768px) {
    display: none;
  }

  & svg {
    width: 300%;
  }

  & svg path {
    d: path('M374.835 507.344C286.972 572.375 259.886 529.612 172.228 496.229C107.874 471.72 -8.27492 474.27 -37.0412 374.356C-65.8074 274.443 -31.5624 50.2571 89.0793 17.9312C209.721 -14.3947 256.825 -14.2785 290.214 110.329C323.602 234.937 564.245 376.251 374.835 507.344Z');
    animation: morph-m 12.5s infinite;
  }

  & svg:hover path {
    d: path('M908.82 408.989C882.826 583.057 803.305 565.209 665.5 627C564.331 672.364 435 807.5 288.5 726.5C142 645.5 -74.2451 351.28 26.3014 177.128C126.848 2.97684 180.607 -50.5181 360.484 53.3335C540.36 157.185 975.214 44.1012 908.82 408.989Z');
  }

  @keyframes morph-m {
    0%, 100% {
      d: path('M374.835 507.344C286.972 572.375 259.886 529.612 172.228 496.229C107.874 471.72 -8.27492 474.27 -37.0412 374.356C-65.8074 274.443 -31.5624 50.2571 89.0793 17.9312C209.721 -14.3947 256.825 -14.2785 290.214 110.329C323.602 234.937 564.245 376.251 374.835 507.344Z');
    }
    50% {
      d: path('M842,346.9c52,78,12.8,293.2-125,355c-101.2,45.4-360.7,36.3-439-69c-110-148-315.8-246.5-215-420  C163.5,38.7,249.8,42.3,467,26.9C805,2.9,748.9,207.2,842,346.9z')
    }
  }
}

